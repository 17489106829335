import React, { useState } from 'react';
import { projects } from '../data/projects';

const ProjectsView = () => {
    const [selectedProject, setSelectedProject] = useState(null);

    const handleProjectClick = (index) => {
        setSelectedProject(selectedProject === index ? null : index);
    };

    return (
        <div className="container mx-auto py-10" id="projects-section">
            <h2 className="text-4xl font-bold mb-6 text-center text-slate-800">Our Projects</h2>
            <div className="flex flex-wrap -mx-4">
                <div className="w-full md:w-full px-4 mb-8">
                    <div className="project-card bg-white shadow-lg p-6 rounded-lg">
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                            {projects.map((project, index) => (
                                <div
                                    key={index}
                                    className={`flex flex-col items-center p-4 bg-gray-100 rounded-lg cursor-pointer transition-transform hover:shadow-lg hover:-translate-y-1 ${
                                        selectedProject === index ? 'bg-blue-600 text-white' : ''
                                    }`}
                                    onClick={() => handleProjectClick(index)}
                                >
                                    <img
                                        src={project.icon}
                                        alt={`${project.title} Icon`}
                                        className="w-12 h-12 mb-3"
                                    />
                                    <h3 className="text-lg font-semibold">{project.title}</h3>
                                </div>
                            ))}
                        </div>

                        {selectedProject !== null && (
                            <div className="project-details text-slate-700 mt-6 p-6 bg-gray-50 rounded-lg shadow-md">
                                <h3 className="text-2xl font-bold text-slate-800">{projects[selectedProject].title}</h3>
                                <p className="mt-3 text-lg">{projects[selectedProject].description}</p>

                                {projects[selectedProject].features && (
                                    <div className="mt-6">
                                        <h4 className="text-xl font-semibold text-slate-800">Features:</h4>
                                        <ul className="mt-3 grid grid-cols-2 gap-4">
                                            {projects[selectedProject].features.map((feature, featureIndex) => (
                                                <li key={featureIndex} className="text-gray-700 text-lg flex items-center">
                                                    <span className="mr-2 text-blue-600">&#9632;</span> {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                {projects[selectedProject].url && (
                                    <div className="mt-6 text-center">
                                        <a
                                            href={projects[selectedProject].url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-semibold"
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectsView;
