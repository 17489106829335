import greenpoint from '../assets/gplogo.png';
import softalmology from '../assets/softalmologylogo.png';
import ldg from '../assets/LDGLogo.png';
import gabbygamiz from '../assets/gglogo.png';
import openmat from '../assets/openmat-logo-black.png';
import prospera from '../assets/prospera-logo.png';
import placeit from '../assets/placeit_selected.png';
import thorlabs from '../assets/thorlabs-logo.jpg';

export const projects = [
    {
        title: 'OpenMat',
        description: 'OpenMat is a BJJ academy management software designed to streamline memberships, payments, attendance tracking, and marketing automation. It provides a fully customizable solution tailored to each academy’s branding and needs.',
        icon: openmat,
        features: [
            'Automated membership and payment processing',
            'Attendance and belt rank tracking',
            'Lead capture and follow-up tools',
            'Online store and private class bookings',
            'Custom website and app integration',
            'Security camera integration and AI analytics',
        ],
        url: 'https://openmat.software/',
    },
    {
        title: 'Prospera',
        description: 'Prospera is a financial planning and investment app designed to help individuals manage savings, investments, and retirement goals. It provides AI-powered financial insights and personalized planning tools.',
        icon: prospera,
        features: [
            'Personalized investment and savings plans',
            'Retirement and long-term wealth projections',
            'Real-time financial analytics',
            'Automated risk assessment and goal tracking',
            'User-friendly interface with secure transactions',
        ],
    },
    {
        title: 'Place It',
        description: 'Place It is a marketplace for event venue rentals, allowing users to browse, book, and manage spaces for various occasions. It connects property owners with clients, ensuring seamless event planning and management.',
        icon: placeit,
        features: [
            'Venue browsing and booking system',
            'Integrated calendar and availability management',
            'Secure payment processing',
            'Vendor and service provider integration',
            'Advanced search and filtering for event needs',
        ],
    },
    {
        title: 'Thorlabs Toolkit',
        description: 'Thorlabs Toolkit is a specialized optical engineering app that provides essential calculations, tools, and reference materials for optical engineers, researchers, and technicians working in precision optics.',
        icon: thorlabs,
        features: [
            'Advanced optical calculators',
            'Material and lens data references',
            'Customizable calculation inputs',
            'User-friendly UI for quick analysis',
            'Mobile-optimized design for lab use',
        ],
    },
    {
        title: 'GreenPoint',
        description: 'GreenPoint is an advanced farm management software that optimizes agricultural operations with crop tracking, inventory management, and analytics tools.',
        icon: greenpoint,
        features: [
            'Streamline farm management processes',
            'Track crop growth and yields effectively',
            'Manage inventory and supplies with ease',
            'Schedule equipment maintenance and tasks',
        ],
    },
    {
        title: 'Softalmology',
        description: 'Softalmology is a comprehensive Electronic Medical Records (EMR) system tailored for ophthalmologists, streamlining patient management and diagnostics.',
        icon: softalmology,
        features: [
            'Efficient patient appointment scheduling',
            'Secure electronic medical record storage',
            'HIPAA-compliant data security',
            'Integration with ophthalmic diagnostic devices',
        ],
        url: 'https://softalmology.com/',
    },
    {
        title: 'Listing Description Generator',
        description: 'An AI-powered tool for real estate agents that generates compelling property descriptions based on property features, market trends, and neighborhood insights.',
        icon: ldg,
        features: [
            'AI-generated property descriptions',
            'SEO-optimized content for listings',
            'Time-saving automation for agents',
            'Adaptable to various market trends',
        ],
        url: 'https://listingdescriptiongenerator.com/',
    },
    {
        title: 'GabbyGamiz',
        description: 'GabbyGamiz is a bakery management solution that optimizes ingredient tracking, recipe organization, and production scheduling for pastry businesses.',
        icon: gabbygamiz,
        features: [
            'Efficient bakery inventory management',
            'Recipe organization and scaling',
            'Production scheduling and task management',
            'Ingredient freshness tracking',
        ],
    },
];
