import React from 'react'
import HtmlRenderer from '../components/HtmlRender'

function ProsperaPrivacyPolicy() {
  const htmlContent = `
<style>
  [data-custom-class='body'], [data-custom-class='body'] * {
    background: transparent !important;
  }
  [data-custom-class='title'], [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
  }
  [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
  }
  [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
  }
  [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
  }
  [data-custom-class='body_text'], [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
  }
  [data-custom-class='link'], [data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
  }
</style>

<div data-custom-class="body">

  <div>
    <strong>
      <span style="font-size: 26px;">
        <span data-custom-class="title">POLÍTICA DE PRIVACIDAD</span>
      </span>
    </strong>
  </div>
  <br/>
  <div>
    <span style="color: rgb(127, 127, 127);">
      <strong>
        <span style="font-size: 15px;">
          <span data-custom-class="subtitle">Última actualización: 05 de marzo de 2025</span>
        </span>
      </strong>
    </span>
  </div>
  <br/><br/>

  <div style="line-height: 1.5;">
    <span style="color: rgb(89, 89, 89); font-size: 15px;" data-custom-class="body_text">
      Bienvenido/a a <strong>Prospera</strong> (o <strong>Prospera Me</strong>), una aplicación que se ejecuta de forma local en tu dispositivo y que
      <strong>no</strong> utiliza servicios externos ni envía tus datos a servidores. La presente Política de Privacidad describe cómo manejamos
      (o en su caso, no manejamos) la información personal en la App, enfatizando que toda la información se almacena únicamente en tu dispositivo.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <span style="color: rgb(89, 89, 89); font-size: 15px;" data-custom-class="body_text">
      <strong>¿Preguntas o inquietudes?</strong> Si después de leer esta política aún tienes dudas, contáctanos en:
      <strong> admin@codingsolved.com </strong>
    </span>
  </div>
  <br/><br/>

  <div style="line-height: 1.5;">
    <strong><span style="font-size: 15px;" data-custom-class="heading_1">1. INFORMACIÓN QUE RECOPILAMOS</span></strong>
  </div>
  <br/>

  <div style="line-height: 1.5;" data-custom-class="body_text">
    <span style="font-size: 15px; color: rgb(89, 89, 89);">
      <em>En resumen:</em> La app <strong>Prospera</strong> no envía ni almacena tu información personal en servidores externos.
      Todo permanece localmente en tu dispositivo (o no se almacena nada en absoluto, según la configuración de la app).
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;" data-custom-class="body_text">
    <span style="font-size: 15px; color: rgb(89, 89, 89);">
      Dependiendo de las funcionalidades que utilices, podrías ingresar datos personales (p.ej., nombre, objetivos financieros, notas personales).
      Tales datos permanecerán <strong>exclusivamente</strong> en tu dispositivo y se usan únicamente para proporcionarte las funciones de la App
      (ejemplo: generar reportes de gastos, recordatorios o estadísticas internas).
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong><span style="font-size: 15px;" data-custom-class="heading_1">2. ALMACENAMIENTO LOCAL</span></strong>
  </div>
  <br/>

  <div data-custom-class="body_text" style="line-height: 1.5;">
    <span style="font-size: 15px; color: rgb(89, 89, 89);">
      <em>En resumen:</em> Toda tu información (si es que se ingresa alguna) se guarda en la memoria local o el sistema de archivos del dispositivo.
      <strong>No</strong> se sincroniza con un servidor ni con servicios de terceros.
    </span>
  </div>
  <br/>

  <ul>
    <li style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);" data-custom-class="body_text">
        <strong>Responsabilidad del usuario:</strong> Es tu responsabilidad mantener tu dispositivo protegido (por ejemplo, con clave de bloqueo, PIN,
        huella dactilar, etc.) y realizar copias de seguridad si lo deseas.
      </span>
    </li>
    <li style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);" data-custom-class="body_text">
        <strong>Ausencia de servidores externos:</strong> No usamos Firebase, no utilizamos Stripe, ni análisis en la nube. Tampoco enviamos datos a
        terceros para publicidad o marketing.
      </span>
    </li>
  </ul>
  <br/>

  <div style="line-height: 1.5;">
    <strong><span style="font-size: 15px;" data-custom-class="heading_1">3. NO COMPARTIMOS DATOS CON TERCEROS</span></strong>
  </div>
  <br/>

  <div data-custom-class="body_text" style="line-height: 1.5;">
    <span style="font-size: 15px; color: rgb(89, 89, 89);">
      <em>En resumen:</em> Cualquier dato que introduzcas se queda en tu dispositivo. No lo enviamos a ningún servidor ni lo compartimos
      con proveedores externos. Por tanto, <strong>no</strong> vendemos, alquilamos ni intercambiamos tu información personal.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong><span style="font-size: 15px;" data-custom-class="heading_1">4. SEGURIDAD</span></strong>
  </div>
  <br/>

  <div data-custom-class="body_text" style="line-height: 1.5;">
    <span style="font-size: 15px; color: rgb(89, 89, 89);">
      Aunque hemos diseñado <strong>Prospera</strong> para que retenga toda tu información de forma local, la seguridad depende en gran parte
      de la protección que tu propio dispositivo tenga.
      No podemos responsabilizarnos de accesos no autorizados debidos a malware, virus o robo físico de tu dispositivo.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong><span style="font-size: 15px;" data-custom-class="heading_1">5. MENORES DE EDAD</span></strong>
  </div>
  <br/>
  <div data-custom-class="body_text" style="line-height: 1.5;">
    <span style="font-size: 15px;">
      <strong>Prospera</strong> no está dirigida a usuarios menores de 18 años y no recopilamos conscientemente información de menores.
      Si descubres que un menor ha introducido datos en la App sin autorización, te recomendamos desinstalar la app o eliminar dicha
      información manualmente desde el dispositivo.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong><span style="font-size: 15px;" data-custom-class="heading_1">6. MODIFICACIONES A ESTA POLÍTICA</span></strong>
  </div>
  <br/>
  <div data-custom-class="body_text" style="line-height: 1.5;">
    <span style="font-size: 15px; color: rgb(89, 89, 89);">
      Podemos actualizar esta Política de Privacidad ocasionalmente para reflejar cambios en la app o en la legislación vigente.
      La versión más reciente estará siempre disponible para su consulta con la fecha de “última actualización” revisada. Te invitamos a revisarla periódicamente.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong><span style="font-size: 15px;" data-custom-class="heading_1">7. CONTACTO</span></strong>
  </div>
  <br/>
  <div data-custom-class="body_text" style="line-height: 1.5;">
    <span style="font-size: 15px;">
      Si tienes dudas o comentarios sobre esta Política de Privacidad, puedes contactarnos en:
      <br/><br/>
      <strong>Correo electrónico:</strong> admin@codingsolved.com
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <span style="font-size: 12px;">
      © 2025 Prospera. Todos los derechos reservados.
    </span>
  </div>

</div>
`;

  return (
    <HtmlRenderer htmlContent={htmlContent} />
  );
}

export default ProsperaPrivacyPolicy;
