import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function OfferingSection() {
    const [selectedOffering, setSelectedOffering] = React.useState(0);
    const [selectedCarrousel, setSelectedCarrousel] = React.useState(window.innerWidth < 768 ? 1 : 0);

    React.useEffect(() => {
        const handleResize = () => {
            setSelectedCarrousel(window.innerWidth < 768 ? 1 : 0);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const offerings = [
        {
            title: 'Mobile App Development',
            content: {
                description: 'We create high-performance mobile applications for Android & iOS, ensuring seamless functionality and AI-driven innovation.',
                dots: [
                    { title: 'Android App Development' },
                    { title: 'iOS App Development' },
                    { title: 'User-Centric Design' },
                    { title: 'Cross-Platform Compatibility' },
                    { title: 'Continuous Support & Updates' }
                ]
            }
        },
        {
            title: 'Advanced Web Development',
            content: {
                description: 'From custom UI/UX design to scalable architecture, our web solutions redefine digital experiences with cutting-edge technology.',
                dots: [
                    { title: 'Custom UI/UX Design' },
                    { title: 'Scalable Architecture' },
                    { title: 'E-Commerce Expertise' },
                    { title: 'Performance Optimization' },
                    { title: 'Data Security & Privacy' }
                ]
            }
        },
        {
            title: 'AI & IoT Solutions',
            content: {
                description: 'We integrate Artificial Intelligence & IoT to optimize business automation, data insights, and real-time monitoring systems.',
                dots: [
                    { title: 'Predictive Analytics' },
                    { title: 'Natural Language Processing' },
                    { title: 'IoT Data Insights' },
                    { title: 'Automation & Process Optimization' },
                    { title: 'Real-time Monitoring & Alerts' }
                ]
            }
        }
    ];

    return (
        <section className="py-16 bg-gray-100">
            <div className="container mx-auto px-6">
                <h2 className="text-4xl font-bold mb-8 text-slate-800 text-center">
                    Explore Our Offerings
                </h2>
                <div className="hidden md:flex">
                    {/* Sidebar Menu */}
                    <div className="w-1/4 pr-6 bg-gray-200 rounded-lg p-6 shadow-lg">
                        <ul className="space-y-4">
                            {offerings.map((offering, index) => (
                                <li
                                    key={index}
                                    className={`cursor-pointer text-lg font-semibold p-3 rounded-lg transition duration-300 ${
                                        selectedOffering === index
                                            ? "bg-blue-600 text-white shadow-md"
                                            : "text-gray-700 hover:bg-blue-500 hover:text-white"
                                    }`}
                                    onClick={() => setSelectedOffering(index)}
                                >
                                    {offering.title}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Main Content */}
                    <div className="w-3/4 bg-white shadow-lg p-10 rounded-lg">
                        <h3 className="text-2xl font-bold text-gray-800">{offerings[selectedOffering].title}</h3>
                        <p className="text-lg text-gray-600 mt-4">{offerings[selectedOffering].content.description}</p>
                        <div className="mt-6 grid grid-cols-2 md:grid-cols-3 gap-4">
                            {offerings[selectedOffering].content.dots.map((dot, dotIndex) => (
                                <div
                                    key={dotIndex}
                                    className="bg-blue-100 text-blue-800 px-4 py-2 rounded-lg text-center font-medium text-sm shadow-sm"
                                >
                                    {dot.title}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Mobile Version - Carousel */}
                {selectedCarrousel === 1 && (
                    <div className="mt-8 md:hidden">
                        <Carousel
                            showArrows={true}
                            showThumbs={false}
                            showIndicators={true}
                            swipeable={true}
                            emulateTouch={true}
                            infiniteLoop={true}
                            autoPlay={true}
                            interval={5000}
                        >
                            {offerings.map((offering, index) => (
                                <div key={index} className="bg-white shadow-lg p-6 rounded-lg">
                                    <h3 className="text-xl font-bold text-gray-800">{offering.title}</h3>
                                    <p className="text-gray-600 mt-2">{offering.content.description}</p>
                                    <div className="mt-4 grid grid-cols-2 gap-2">
                                        {offering.content.dots.map((dot, dotIndex) => (
                                            <div
                                                key={dotIndex}
                                                className="bg-blue-100 text-blue-800 px-3 py-1 rounded-lg text-center text-sm font-medium shadow-sm"
                                            >
                                                {dot.title}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                )}
            </div>
        </section>
    );
}

export default OfferingSection;
