import React from 'react'
import NavBar from '../components/Navbar'
import HeaderCS from '../components/HeaderCS'
import OfferingSection from '../components/Offering'
import ProjectsView from '../components/Projects'

function Home() {
  return (
    <div>
    <NavBar></NavBar>
    <HeaderCS></HeaderCS>
    <OfferingSection></OfferingSection>
    <ProjectsView></ProjectsView>
    </div>
  )
}

export default Home