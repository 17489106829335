import React from 'react'
import HtmlRenderer from '../components/HtmlRender'

function PlaceItPrivacyPolicy() {
  const htmlContent = `
<style>
  [data-custom-class='body'], [data-custom-class='body'] * {
    background: transparent !important;
  }
  [data-custom-class='title'], [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
  }
  [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
  }
  [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
  }
  [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
  }
  [data-custom-class='body_text'], [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
  }
  [data-custom-class='link'], [data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
  }
</style>

<div data-custom-class="body">
  <div>
    <strong>
      <span style="font-size: 26px;">
        <span data-custom-class="title">POLÍTICA DE PRIVACIDAD</span>
      </span>
    </strong>
  </div>
  <br/>
  <div>
    <span style="color: rgb(127, 127, 127);">
      <strong>
        <span style="font-size: 15px;">
          <span data-custom-class="subtitle">Última actualización: 05 de marzo de 2025</span>
        </span>
      </strong>
    </span>
  </div>
  <br/><br/>
  <div style="line-height: 1.5;">
    <span style="color: rgb(89, 89, 89); font-size: 15px;" data-custom-class="body_text">
      Esta Política de Privacidad para <strong>PlaceIt</strong> describe cómo y por qué podemos recopilar, almacenar, usar y/o compartir tu información cuando utilizas nuestros servicios,
      tales como cuando:
    </span>
  </div>
  <ul>
    <li style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);" data-custom-class="body_text">
        Visitas nuestro sitio web oficial en
        <a href="https://codingsolved.com/placeit" target="_blank" class="link">https://codingsolved.com/placeit</a>
        o cualquier otro sitio de PlaceIt que enlace a esta política de privacidad.
      </span>
    </li>
    <li style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);" data-custom-class="body_text">
        Descargas y usas nuestra aplicación móvil <strong>PlaceIt</strong> (disponible en tiendas como Google Play o App Store) que haga referencia a esta política.
      </span>
    </li>
    <li style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);" data-custom-class="body_text">
        Interactúas con nosotros en otros aspectos relacionados con ventas, marketing o eventos.
      </span>
    </li>
  </ul>

  <div style="line-height: 1.5;">
    <span style="font-size: 15px;" data-custom-class="body_text">
      <strong>¿Preguntas o inquietudes?</strong> Leer esta política de privacidad te ayudará a entender tus derechos y opciones en materia de protección de datos.
      Si no estás de acuerdo con nuestras prácticas, por favor no uses nuestros Servicios.
      Si aún tienes dudas o comentarios, contáctanos en
      <strong>admin@codingsolved.com</strong>.
    </span>
  </div>
  <br/><br/>

  <div style="line-height: 1.5;">
    <strong>
      <span style="font-size: 15px;" data-custom-class="heading_1">
        1. ¿QUÉ INFORMACIÓN RECOPILAMOS?
      </span>
    </strong>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <span style="font-size: 15px;" data-custom-class="heading_2"><strong>Información que nos proporcionas voluntariamente</strong></span>
  </div>
  <br/>

  <div style="line-height: 1.5;" data-custom-class="body_text">
    <span style="font-size: 15px; color: rgb(89, 89, 89);">
      <em>En resumen:</em> Recopilamos la información personal que nos proporcionas directamente cuando te registras o usas nuestras funciones.
    </span>
  </div>
  <br/>

  <div data-custom-class="body_text" style="line-height: 1.5;">
    <span style="font-size: 15px; color: rgb(89, 89, 89);">
      Los datos personales que recopilamos dependen del contexto de tus interacciones con PlaceIt, las elecciones que hagas, así como los productos y funcionalidades que utilices.
      Esto puede incluir:
    </span>
  </div>
  <ul>
    <li style="line-height: 1.5;"><span style="font-size: 15px;" data-custom-class="body_text">Nombre completo y datos de contacto (correo electrónico, teléfono, etc.).</span></li>
    <li style="line-height: 1.5;"><span style="font-size: 15px;" data-custom-class="body_text">Información de pago (tarjeta de crédito/débito, cuenta Stripe, etc.) si realizas transacciones en la plataforma.</span></li>
    <li style="line-height: 1.5;"><span style="font-size: 15px;" data-custom-class="body_text">Dirección postal y facturación cuando sea necesario.</span></li>
    <li style="line-height: 1.5;"><span style="font-size: 15px;" data-custom-class="body_text">Otros datos que decidas compartir, por ejemplo, al completar tu perfil o al enviarnos consultas.</span></li>
  </ul>
  <br/>

  <div style="line-height: 1.5;" data-custom-class="body_text">
    <span style="font-size: 15px; color: rgb(89, 89, 89);">
      <strong>Información recopilada automáticamente</strong>. También obtenemos automáticamente cierta información técnica cuando navegas o usas nuestra app,
      como tu dirección IP, tipo de dispositivo, sistema operativo, identificadores de dispositivos, información de ubicación aproximada y datos de uso (páginas visitadas, acciones en la app, etc.).
      Esto se realiza, por ejemplo, mediante cookies y tecnologías similares.
    </span>
  </div>

  <br/>
  <div style="line-height: 1.5;">
    <strong>
      <span style="font-size: 15px;" data-custom-class="heading_1">
        2. ¿CÓMO PROCESAMOS TU INFORMACIÓN?
      </span>
    </strong>
  </div>
  <br/>
  <div data-custom-class="body_text" style="line-height: 1.5;">
    <span style="font-size: 15px;">
      <em>En resumen:</em> Utilizamos tu información para proveer y mejorar nuestros servicios, comunicarnos contigo, cumplir con requerimientos legales y prevenir fraudes.
    </span>
  </div>
  <br/>
  <ul>
    <li>
      <span style="font-size: 15px;" data-custom-class="body_text">
        Proporcionar y gestionar la cuenta de usuario (autenticación, soporte, notificaciones, etc.).
      </span>
    </li>
    <li>
      <span style="font-size: 15px;" data-custom-class="body_text">
        Procesar pagos a través de Stripe (u otros procesadores de pago) y registrar transacciones.
      </span>
    </li>
    <li>
      <span style="font-size: 15px;" data-custom-class="body_text">
        Realizar análisis y estadísticas con herramientas como Google Analytics o Firebase Analytics para comprender y mejorar la experiencia de usuario.
      </span>
    </li>
    <li>
      <span style="font-size: 15px;" data-custom-class="body_text">
        Prevenir actividades ilícitas y fraudes, detectar violaciones a nuestros términos y condiciones, y garantizar la seguridad de la plataforma.
      </span>
    </li>
  </ul>
  <br/>

  <div style="line-height: 1.5;">
    <strong>
      <span style="font-size: 15px;" data-custom-class="heading_1">
        3. ¿QUÉ BASES LEGALES APLICAN PARA EL TRATAMIENTO?
      </span>
    </strong>
  </div>
  <br/>
  <div style="line-height: 1.5;" data-custom-class="body_text">
    <span style="font-size: 15px; color: rgb(89, 89, 89);">
      Dependiendo de tu ubicación y de la normativa aplicable (p.ej. RGPD en la UE, leyes nacionales, etc.),
      nos basamos en tu consentimiento, la necesidad contractual (para ofrecerte el servicio), nuestros intereses legítimos o el cumplimiento de una obligación legal para procesar tus datos.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong>
      <span style="font-size: 15px;" data-custom-class="heading_1">
        4. ¿CUÁNDO Y CON QUIÉN COMPARTIMOS TU INFORMACIÓN?
      </span>
    </strong>
  </div>
  <br/>
  <div style="line-height: 1.5;" data-custom-class="body_text">
    <span style="font-size: 15px;">
      <em>En resumen:</em> Podemos compartir información con proveedores externos confiables para procesar datos en nuestro nombre,
      tales como servicios de alojamiento en la nube (Firebase, Google Cloud Platform), pasarelas de pago (Stripe) y servicios analíticos o de marketing (Google Analytics).
    </span>
  </div>
  <br/>
  <ul>
    <li>
      <span style="font-size: 15px;" data-custom-class="body_text">
        <strong>Proveedores de servicios (Third-party services):</strong> Alojamiento en la nube, pasarelas de pago (Stripe), soluciones analíticas (Firebase, Google Analytics, etc.).
      </span>
    </li>
    <li>
      <span style="font-size: 15px;" data-custom-class="body_text">
        <strong>Obligaciones legales:</strong> Podríamos divulgar datos para cumplir con la ley, responder a procesos judiciales o proteger los derechos,
        propiedad o seguridad de PlaceIt, nuestros usuarios u otros.
      </span>
    </li>
    <li>
      <span style="font-size: 15px;" data-custom-class="body_text">
        <strong>Transferencias empresariales:</strong> En caso de fusiones, adquisiciones o venta de activos, tus datos podrían ser parte de la transacción.
      </span>
    </li>
  </ul>
  <br/>

  <div style="line-height: 1.5;">
    <strong>
      <span style="font-size: 15px;" data-custom-class="heading_1">
        5. ¿CUÁNTO TIEMPO CONSERVAMOS TUS DATOS?
      </span>
    </strong>
  </div>
  <br/>
  <div style="line-height: 1.5;" data-custom-class="body_text">
    <span style="font-size: 15px;">
      Conservamos tu información personal solo mientras sea necesaria para cumplir los propósitos descritos o mientras la ley lo exija.
      Una vez que ya no sea requerida, la eliminaremos o anonimizaremos de forma segura.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong>
      <span style="font-size: 15px;" data-custom-class="heading_1">
        6. ¿CÓMO PROTEGEMOS TU INFORMACIÓN?
      </span>
    </strong>
  </div>
  <br/>
  <div style="line-height: 1.5;" data-custom-class="body_text">
    <span style="font-size: 15px;">
      Hemos implementado medidas de seguridad técnicas y organizativas razonables para resguardar tus datos. Sin embargo,
      ninguna transmisión por Internet o método de almacenamiento es 100% seguro, por lo que no podemos garantizar una seguridad absoluta.
      Tú eres responsable de mantener la confidencialidad de tus credenciales y del uso apropiado de la app.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong>
      <span style="font-size: 15px;" data-custom-class="heading_1">
        7. ¿RECOPILAMOS INFORMACIÓN DE MENORES?
      </span>
    </strong>
  </div>
  <br/>
  <div style="line-height: 1.5;" data-custom-class="body_text">
    <span style="font-size: 15px;">
      PlaceIt no está dirigido a menores de 18 años. No recopilamos de forma consciente información personal de menores.
      Si crees que un menor ha proporcionado datos sin consentimiento, contáctanos para eliminarlos.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong>
      <span style="font-size: 15px;" data-custom-class="heading_1">
        8. ¿CUÁLES SON TUS DERECHOS DE PRIVACIDAD?
      </span>
    </strong>
  </div>
  <br/>
  <div data-custom-class="body_text" style="line-height: 1.5;">
    <span style="font-size: 15px;">
      Dependiendo de tu ubicación y de la ley aplicable, puedes tener ciertos derechos sobre tu información,
      tales como acceder, rectificar, eliminar o limitar el uso de tus datos personales.
      Para ejercer estos derechos, envíanos un correo a <strong>admin@codingsolved.com</strong>.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong>
      <span style="font-size: 15px;" data-custom-class="heading_1">
        9. PROHIBICIÓN DE ACTIVIDADES ILÍCITAS
      </span>
    </strong>
  </div>
  <br/>
  <div data-custom-class="body_text" style="line-height: 1.5;">
    <span style="font-size: 15px;">
      No se permiten actividades ilegales, fraude o conductas que violen nuestras políticas o leyes vigentes.
      El uso abusivo o engañoso puede derivar en la suspensión o terminación de tu cuenta, o incluso notificar a las autoridades competentes.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong>
      <span style="font-size: 15px;" data-custom-class="heading_1">
        10. MODIFICACIONES A ESTA POLÍTICA
      </span>
    </strong>
  </div>
  <br/>
  <div style="line-height: 1.5;" data-custom-class="body_text">
    <span style="font-size: 15px;">
      Podemos actualizar esta política de privacidad periódicamente para reflejar cambios en nuestras prácticas o en la normativa aplicable.
      Publicaremos la versión actualizada con la nueva fecha de “última actualización”. Te recomendamos revisarla con frecuencia.
    </span>
  </div>
  <br/>

  <div style="line-height: 1.5;">
    <strong>
      <span style="font-size: 15px;" data-custom-class="heading_1">
        11. ¿CÓMO CONTACTARNOS?
      </span>
    </strong>
  </div>
  <br/>
  <div data-custom-class="body_text" style="line-height: 1.5;">
    <span style="font-size: 15px; color: rgb(89, 89, 89);">
      Si tienes preguntas o comentarios sobre esta política de privacidad, contáctanos por correo electrónico a:
      <strong> admin@codingsolved.com </strong>
    </span>
  </div>
  <br/><br/>
  <div style="line-height: 1.5;">
    <span style="font-size: 12px;" data-custom-class="body_text">
      © 2025 PlaceIt / Coding Solved LLC. Todos los derechos reservados.
    </span>
  </div>
</div>
`;

  return (
    <HtmlRenderer htmlContent={htmlContent} />
  );
}

export default PlaceItPrivacyPolicy;
