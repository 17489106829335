import React, { useState, useEffect } from "react";
import bg from "../assets/bg1.png";
import { db, storage } from "../firebaseConfig";
import { collection, getDoc, doc, updateDoc, serverTimestamp, setDoc, Timestamp } from "firebase/firestore";
import { useParams } from "react-router-dom";
import userNotFoundLogo from "../assets/error404.png";
import NavBar from "./Navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';




function Profile() {
  const [cardData, setCardData] = useState(null);
  const { id } = useParams(); // Obtenemos el parámetro de la URL
  const [loading, setLoading] = useState(true);
  const [userNotFound, setUserNotFound] = useState(false);
  const [softalogyUserId, setSoftalogyUserId] = useState("");
  const [cardId, setCardId] = useState(id);
  const [isProfileOpened, setIsProfileOpened] = useState(false); // State to track if profile is opened

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingSubtitle, setIsEditingSubtitle] = useState(false);

  const [titleController, setTitleController] = useState(cardData?.title);
  //subtile, img, contactURL, buttons
  const [subtitleController, setSubtitleController] = useState(cardData?.subtitle);
  const [imgController, setImgController] = useState(cardData?.img);
  const [contactURLController, setContactURLController] = useState(cardData?.contactURL);
  const [buttonsController, setButtonsController] = useState(cardData?.buttons);
  const [image, setImage] = useState(cardData?.img); // El estado para la URL de la imagen
  const fileInputRef = React.createRef(); // Ref para el input de tipo 'file'



  // can edit
  const [canEdit, setCanEdit] = useState(id.includes("edit"));


  useEffect(() => {
    if (id.includes("edit")) {
      console.log("includes edit");
      const parts = id.split("edit");
      const newCardId = parts[0]; // Todo lo que está antes de "edit"
      console.log(newCardId);
      const newSoftalogyUserId = parts[1]; // Todo lo que está después de "edit"

      setCardId(newCardId);
      setSoftalogyUserId(newSoftalogyUserId);
      setCanEdit(true);


    } else {
      // Si no contiene "edit", el ID de la tarjeta es el mismo ID de la URL
      setCardId(id);
    }
    const fetchData = async () => {
      try {
        console.log("fetching data from card" + cardId);

        const data = await getDoc(doc(collection(db, "cards"), id.includes("edit") ? id.split("edit")[0] : id));

        if (!data.exists()) {
          setUserNotFound(true);
        } else {
          const actualData = data.data(); // Obtener los datos del documento
          setCardData(actualData);
          // Inicializa los controladores con los datos actuales
          setTitleController(actualData.title); // Usa actualData aquí
          setSubtitleController(actualData.subtitle);
          setImgController(actualData.img);
          setContactURLController(actualData.contactURL);
          setButtonsController(actualData.buttons);
          setImage(actualData.img); // Actualiza la imagen en el estado
          console.log("data exists");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const checkProfileOpened = async () => {
      try {
        const profileDoc = doc(collection(db, "cards"), id);
        const profileData = await getDoc(profileDoc);

        if (profileData.exists()) {
          // Crear un UID basado en la fecha y hora actual
          const now = new Date();
          const day = now.getDate().toString().padStart(2, "0");
          const month = (now.getMonth() + 1).toString().padStart(2, "0");
          const year = now.getFullYear().toString();
          const hour = now.getHours().toString().padStart(2, "0");
          const minute = now.getMinutes().toString().padStart(2, "0");
          const second = now.getSeconds().toString().padStart(2, "0");

          const uid = `${day}${month}${year}${hour}${minute}${second}`;

          // Ruta al documento de registro
          const logsCollectionRef = collection(profileDoc, "logs");
          const logDocumentRef = doc(logsCollectionRef, uid);

          // Datos para el registro
          const logData = {
            timestamp: serverTimestamp(),
          };

          // Actualizar el documento con merge
          await updateDoc(profileDoc, { isOpened: true });

          // Crear el registro en la colección de logs
          await setDoc(logDocumentRef, logData);

          setIsProfileOpened(true); // Establecer el estado en true
        }
      } catch (error) {
        console.error("Error updating data:", error);
      }
    };
    console.log("isEditing")

    fetchData();
    checkProfileOpened();
  }, []);


  if (loading) {
    return (
      <div className="h-screen flex flex-col items-center justify-center bg-slate-100">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        <p className="mt-4 font-bold text-gray-800">Loading...</p>
      </div>
    );
  }

  if (userNotFound) {
    return (
      <div>
        <NavBar></NavBar>
        <div className="h-screen flex flex-col items-center justify-center bg-slate-100">
          <img src={userNotFoundLogo} alt="User Not Found" className="w-20 h-20 mb-4" />
          <p className="text-gray-800 font-bold text-xl mb-4 text-center w-80 
        ">The URL entered is not valid or the user was not found.</p>
        </div>
      </div>
    );
  }

  const handleSave = async () => {
    try {
      // Guarda los datos en Firestore
      const docRef = doc(db, "cards", cardId);
      await updateDoc(docRef, {
        title: titleController,
        // otros campos que quieras actualizar
      });

      // Actualiza el estado local
      setCardData(prevData => ({
        ...prevData,
        title: titleController,
      }));

      // Salir del modo edición
      setIsEditing(false);

    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleSaveSubtitle = async () => {
    try {
      // Guarda los datos en Firestore
      const docRef = doc(db, "cards", cardId);
      await updateDoc(docRef, {
        subtitle: subtitleController,
        // otros campos que quieras actualizar
      });

      // Actualiza el estado local
      setCardData(prevData => ({
        ...prevData,
        subtitle: subtitleController,
      }));

      // Salir del modo edición para el subtítulo
      setIsEditingSubtitle(false);

    } catch (error) {
      console.error("Error saving subtitle:", error);
    }
  };


    // Función para manejar el cambio de imagen
    const handleImageChange = async (e) => {
      const file = e.target.files[0];
      if (!file) return;
      console.log("file", file);
      const imageRef = storageRef(storage, `profile_images/${file.name}`);
      const snapshot = await uploadBytes(imageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      setImage(downloadURL); // Actualizamos la imagen en el estado
      setImgController(downloadURL); // Actualizamos el controlador de imagen
      await updateDoc(doc(db, "cards", cardId), { img: downloadURL });

      // Aquí deberías también actualizar Firestore con la nueva URL de la imagen
    };

  // Función para activar el input de archivo oculto
  const triggerFileSelectPopup = () => fileInputRef.current.click();





  return (
    <div
      className="h-screen flex flex-col justify-center bg-center bg-cover bg-no-repeat bg-slate-100"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPosition: "bottom",
        backgroundSize: "100% auto",
      }}
    >
      <div className="flex flex-col items-center text-white">
        <div className="flex flex-col items-center text-white">
          {isEditing ? (
            <div>
              <input
                type="text"
                value={titleController}
                onChange={(e) => setTitleController(e.target.value)}
                placeholder="Enter your title here..."
                className="text-4xl font-bold mb-6 text-slate-800"
                autoFocus
              />
              <button
                onClick={handleSave}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Save
              </button>
            </div>
          ) : (
            <h1 className="text-4xl font-bold mb-6 text-slate-800">
              {cardData?.title}
              {canEdit && (
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => setIsEditing(true)}
                  className="ml-2 cursor-pointer"
                />
              )}
            </h1>
          )}

        </div>

        <p className="text-2xl font-semibold mb-6 text-gray-500">
          <p className="text-2xl font-semibold mb-6 text-gray-500">
            {isEditingSubtitle ? (
              <div>
                <input
                  type="text"
                  value={subtitleController}
                  onChange={(e) => setSubtitleController(e.target.value)}
                  placeholder="Enter your subtitle here..."
                  className="text-2xl font-semibold mb-6 text-gray-500"
                  style={{ width: '100%' }} // Asegura que el input tenga el 100% del ancho del contenedor
                  autoFocus
                />
                <button
                  onClick={handleSaveSubtitle}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Save
                </button>
              </div>
            ) : (
              <span>
                {cardData?.subtitle}
                {canEdit && (
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => setIsEditingSubtitle(true)}
                    className="ml-2 cursor-pointer"
                  />
                )}
              </span>
            )}
          </p>

        </p>
        <div>
          <div className="relative">
            <img
              src={image}
              alt="Profile"
              className="w-[250px] h-[250px] mx-auto rounded-full border-white border-4 object-cover"
              style={{ marginBottom: "-30%", zIndex: 1 }}
            />
             {canEdit && (
          <FontAwesomeIcon
          icon={faEdit}
          onClick={triggerFileSelectPopup}
          className="absolute right-0 bottom-0 cursor-pointer"
          style={{ margin: '4px' }} // Ajusta este estilo según sea necesario
        />
          )}
            
            <input
              type="file"
              ref={fileInputRef}
              accept="image/png, image/jpeg, image/gif, image/jpg"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
          </div>
        </div>
       
        <div className="flex flex-col items-center text-white pt-28">
          <button
            className="text-2xl bg-gray-600 bg-opacity-80 text-white font-bold py-2 px-6 rounded-full border-2 border-white"
            onClick={() => {

              console.log('contactData');
              if(!cardData?.vcf) return;

              const contactData = cardData?.vcf;

              if (contactData.startsWith('BEGIN:VCARD')) {
                // We have a VCF string, create a Blob and download
                const vcfBlob = new Blob([contactData], { type: 'text/vcard' });
                const vcfUrl = URL.createObjectURL(vcfBlob);

                // Create a temporary anchor to initiate download
                const tempLink = document.createElement('a');
                tempLink.href = vcfUrl;
                tempLink.download = 'contact.vcf'; // Name of the file to be downloaded
                document.body.appendChild(tempLink); // Required for Firefox
                tempLink.click();

                // Clean up the URL object and the temporary link
                URL.revokeObjectURL(vcfUrl);
                document.body.removeChild(tempLink);
              } else {
                // We have a URL, open it in a new tab
                console.log('Opening contact in a new tab');
                window.open(contactData, '_blank');
              }

              /* Handle button click */
            }}
          >
            Import Contact
          </button>
        </div>

        <div className="mt-6 flex space-x-4">
          {cardData?.buttons &&
            cardData?.buttons.map((button, index) => (
              <a
                key={index}
                href={button.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={button.logo}
                  alt={`Button ${index + 1}`}
                  className="w-12 h-12 rounded-full border-white border-2"
                />
              </a>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Profile;
